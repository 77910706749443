export const assetList = [
  {
    id: 1,
    allocations: "Team",
    tokenPercentage: "18.00%",
    tokenAllocation: "270,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 6,750,000,00",
    cliffPeriod: "12 months",
    vestingPeriod: "12 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "0.00%",
    unlockHash: "0",
    unlockVest: "270,000,000",
  },
  {
    id: 2,
    allocations: "Advisors and Partners",
    tokenPercentage: "1.00%",
    tokenAllocation: "15,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 375,000,0000",
    cliffPeriod: "6 months",
    vestingPeriod: "12 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "10.00%",
    unlockHash: "1,500,000",
    unlockVest: "13,500,000",
  },
  {
    id: 3,
    allocations: "Ecosystem development",
    tokenPercentage: "22.00%",
    tokenAllocation: "330,000,000",
    amountToRaise: "$ 8,250,000,00",
    tokenprice: "$ 0.0250",
    cliffPeriod: "12 months",
    vestingPeriod: "36 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "10.00%",
    unlockHash: "33,000,000",
    unlockVest: "297,000,000",
  },
  {
    id: 4,
    allocations: "Airdrops",
    tokenPercentage: "3.00%",
    tokenAllocation: "45,000,000",
    tokenprice: "$ 0.0250",
    cliffPeriod: "0 months",
    vestingPeriod: "0 months",
    amountToRaise: "$ 1,125,000,00",
    total: "19/07/24 08:42 pm",
    unlockPer: "100.00%",
    unlockHash: "45,000,000",
    unlockVest: "0",
  },
  {
    id: 5,
    allocations: "Marketing",
    tokenPercentage: "10.00%",
    tokenAllocation: "150,000,000",
    amountToRaise: "$ 3,750,000,00",
    tokenprice: "$ 0.0250",
    cliffPeriod: "9 months",
    vestingPeriod: "12 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "25.00%",
    unlockHash: "37,500,000",
    unlockVest: "112,500,000",
  },
  {
    id: 6,
    allocations: "Ambassadors",
    tokenPercentage: "0.00%",
    tokenAllocation: "0",
    tokenprice: "$ 0.0250",
    amountToRaise: "-",
    cliffPeriod: "0 months",
    vestingPeriod: "0 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "0.00%",
    unlockHash: "0",
    unlockVest: "0",
  },
  {
    id: 7,
    allocations: "Ecosystem rewards",
    tokenPercentage: "0.00%",
    tokenAllocation: "0",
    tokenprice: "$ 0.0250",
    amountToRaise: "-",
    cliffPeriod: "0 months",
    vestingPeriod: "0 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "20.00%",
    unlockHash: "0",
    unlockVest: "0",
  },
  {
    id: 8,
    allocations: "Staking rewards",
    tokenPercentage: "4.00%",
    tokenAllocation: "60,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 1,500,000,00",
    cliffPeriod: "0 months",
    vestingPeriod: "0 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "100.00%",
    unlockHash: "60,000,000",
    unlockVest: "0",
  },
  {
    id: 9,
    allocations: "Treasury",
    tokenPercentage: "15.00%",
    tokenAllocation: "225,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 5,625,000,00",
    cliffPeriod: "12 months",
    vestingPeriod: "24 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "10.00%",
    unlockHash: "22,500,000",
    unlockVest: "202,500,000",
  },
  {
    id: 10,
    allocations: "Liquidity / Exchanges",
    tokenPercentage: "15.00%",
    tokenAllocation: "225,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 5,625,000,00",
    cliffPeriod: "12 months",
    vestingPeriod: "24 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "10.00%",
    unlockHash: "22,500,000",
    unlockVest: "202,500,000",
  },
  {
    id: 11,
    allocations: "Seed round",
    tokenPercentage: "6.00%",
    tokenAllocation: "90,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 2,250,000,00",
    cliffPeriod: "12 months",
    vestingPeriod: "9 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "5.00%",
    unlockHash: "4,500,000",
    unlockVest: "85,500,000",
  },
  {
    id: 12,
    allocations: "Private round",
    tokenPercentage: "2.00%",
    tokenAllocation: "30,000,000",
    tokenprice: "$ 0.0150",
    amountToRaise: "$ 450,000,00",
    cliffPeriod: "12 months",
    vestingPeriod: "9 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "5.00%",
    unlockHash: "1,500,000",
    unlockVest: "28,500,000",
  },
  {
    id: 13,
    allocations: "Public round",
    tokenPercentage: "4.00%",
    tokenAllocation: "60,000,000",
    tokenprice: "$ 0.0250",
    amountToRaise: "$ 1,500,000,00",
    cliffPeriod: "0 months",
    vestingPeriod: "0 months",
    total: "19/07/24 08:42 pm",
    unlockPer: "0.00%",
    unlockHash: "0",
    unlockVest: "60,000,000",
  },
];
